import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Document, Page, View, Text, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { DownloadOutlined } from '@mui/icons-material'
import { getTotalShiftHours, timezoneOffsets, parseTimeBlock, splitShiftsIntoBlocks } from '../../Utilities/shiftUtils'
import PdfEmploymentTable from './PdfEmploymentTable'
import yu from '../../fonts/yumin.ttf'
import PdfSalesTable from './PdfSalesTable'
import moment from 'moment'
import PdfFooter from './PdfFooter'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { getStaffReq, getShifts } from '../CheckShift/CheckShiftDay'
moment.locale('ja')
moment.updateLocale('en', {
    weekdaysShort: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日']
})

export const priceFormatter = price => {
    return price ? String(Math.round(price)).slice(0, -3) : '-'
}

const PdfBulkExportButton = ({
    shiftDates = [],
    buttonText = '',
    setSelectedDates = () => {},
    setDrawerOpen = () => {}
}) => {
    const { employmentId } = useParams()
    const [dialogue, setDialogue] = useState(false)
    const [hourlyPleds, setHourlyPreds] = useState({})
    const [staffPerHour, setStaffPerHour] = useState({})
    const [employmentShifts, setEmploymentShifts] = useState({})
    const [pageBreak, setPageBreak] = useState({})
    const [hourlyEmpSalesPred, setHourlyEmpSalesPred] = useState({})
    const [startHour, setStartHour] = useState({})
    const [endHour, setEndHour] = useState({})
    const [hours, setHours] = useState({})
    const [leftColWidth, setLeftColWidth] = useState({})
    const [hoursWithStaffReq, setHoursWithStaffReq] = useState({})
    const [totalActualStaff, setTotalActualStaff] = useState({})
    const [workShiftData, setWorkShiftData] = useState({})
    const [failedToFecth, setFailedToFecth] = useState(false)
    const [doneProcessing, setDoneProcessing] = useState(false)
    const getWorkShiftData = () => {
        if (shiftDates.length > 0) {
            AxiosWithAuth.get('pdfs/workshift', {
                params: { date: shiftDates.join(','), employment_id: employmentId }
            })
                .then(res => {
                    setWorkShiftData({
                        shifts: res.data.shifts,
                        staffRequirements: res.data.staff_requirements,
                        operationHours: res.data.operation_hours,
                        storeHours: res.data.store_hours,
                        shiftRequests: res.data.shift_requests,
                        dailyNotes: res.data.daily_notes,
                        predictedSales: res.data.predicted_sales,
                        store: res.data.store,
                        employments: res.data.employments.sort((a, b) => a.order - b.order)
                    })
                })
                .catch(() => {
                    setFailedToFecth(true)
                })
        }
    }

    useEffect(() => {
        if (Object.keys(workShiftData).length > 0 && shiftDates.length > 0) {
            const hoursWithStaffReq = {}
            const missingStaffHours = {}
            const totalActualStaff = {}
            const combinedShifts = {}
            const splittedShifts = {}
            shiftDates.forEach(shiftDate => {
                let actualStaffCount = 0
                const [, hoursWithStaffRequirement] = getStaffReq(
                    workShiftData?.staffRequirements?.[shiftDate],
                    workShiftData?.operationHours?.[shiftDate],
                    shiftDate
                )
                let [shiftChunk, shiftRequests, fusokuCount] = getShifts(
                    workShiftData?.shifts[shiftDate],
                    workShiftData?.store,
                    workShiftData?.shiftRequests[shiftDate]
                )
                for (let i = 1; i <= fusokuCount; i++) {
                    const existedEmployment = workShiftData?.employments.find(emp => emp.id === 'fusoku' + i)
                    if (!existedEmployment) {
                        workShiftData?.employments.push({
                            id: 'fusoku' + i,
                            emp_cd: i,
                            user: {
                                name_first: `${i}`,
                                name_last: '不足シフト'
                            }
                        })
                    }
                }
                shiftChunk = shiftChunk.filter(shift => shift.status === 'confirmed')
                const combinedShift = [...shiftChunk, ...shiftRequests]
                const splittedShift = splitShiftsIntoBlocks(combinedShift)
                const nextDayEnd = workShiftData?.operationHours?.[shiftDate]?.next_day_end
                let endHour = parseInt(workShiftData?.operationHours?.[shiftDate]?.close_time?.split(':')[0])
                endHour = nextDayEnd ? endHour + 24 : endHour
                const opsHoursArr = Array.from(Array(endHour - 7), (x, i) => i + 7)
                const checkIfFullyStaffed = (day, hour) => {
                    let shiftCount = 0
                    combinedShift.forEach(shift => {
                        if (
                            shift.status === 'confirmed' &&
                            shift.startDate.getHours() === hour &&
                            shift.startDate.getDate() === day
                        ) {
                            shiftCount++
                        }
                    })
                    return shiftCount / 4
                }
                const missingStaffHour = {}
                opsHoursArr.forEach(hour => {
                    const date = parseInt(shiftDate.split('-')[2])
                    const staffRequired = hoursWithStaffRequirement[`${date}-${hour}`]
                    const shiftCount = checkIfFullyStaffed(date, hour)

                    missingStaffHour[moment(`${shiftDate} ${hour}:00:00`).format('YYYY-MM-DD-HH')] =
                        staffRequired - shiftCount
                })
                splittedShift.forEach(shift => {
                    if (shift.status === 'confirmed') {
                        actualStaffCount += 0.25
                    }
                })
                hoursWithStaffReq[shiftDate] = hoursWithStaffRequirement
                totalActualStaff[shiftDate] = actualStaffCount
                missingStaffHours[shiftDate] = missingStaffHour
                combinedShifts[shiftDate] = combinedShift
                splittedShifts[shiftDate] = splittedShift
            })

            setHoursWithStaffReq(hoursWithStaffReq)
            setTotalActualStaff(totalActualStaff)

            if (missingStaffHours) {
                const hourlyPredsObj = {}
                shiftDates.forEach(shiftDate => {
                    const preds = []
                    workShiftData?.staffRequirements?.[shiftDate]?.forEach(staff => {
                        const { hour, pred_sales, customer_preds, customer_staff_required, staff_required } = staff
                        preds[hour] = {
                            pred_sales: customer_preds || pred_sales,
                            staff_required: customer_staff_required || staff_required
                        }
                    })
                    Object.entries(missingStaffHours?.[shiftDate]).forEach(([time, val]) => {
                        const hour = time.split('-')[3]
                        const missing = val > 0 ? val : 0
                        preds[parseInt(hour)] = {
                            ...preds[parseInt(hour)],
                            missing
                        }
                    })
                    hourlyPredsObj[shiftDate] = preds
                })
                setHourlyPreds(hourlyPredsObj)

                if (hoursWithStaffReq) {
                    const hourlyEmpSalesPreds = {}
                    shiftDates.forEach(shiftDate => {
                        const predicted =
                            workShiftData?.predictedSales[shiftDate].customer_predicted_sales ||
                            workShiftData?.predictedSales[shiftDate].predicted_sales
                        hourlyEmpSalesPreds[shiftDate] = totalActualStaff && predicted 
                            ? Math.round(predicted / totalActualStaff[shiftDate]).toLocaleString('ja-JP')
                            : '0'
                    })
                    setHourlyEmpSalesPred(hourlyEmpSalesPreds)
                }
                const startHours = {}
                const endHours = {}
                const hours = {}
                const leftColWidths = {}
                shiftDates.forEach(shiftDate => {
                    const start = parseInt(workShiftData?.operationHours?.[shiftDate]?.open_time.split(':')) || 7
                    let end = parseInt(workShiftData?.operationHours?.[shiftDate]?.close_time.split(':')) || start + 21

                    end <= start ? (end += 24) : end
                    const hour = Array.from(Array(end - start), (x, i) => i + start)
                    // we need 21 hour columns in order to fit the table, 1 column is 19.0px
                    const missingWidth = (21 - (hour[hour.length - 1] - hour[0] + 1)) * 19.0
                    const leftColWidth = 130.0 + missingWidth + 'px'
                    startHours[shiftDate] = start
                    endHours[shiftDate] = end
                    hours[shiftDate] = hour
                    leftColWidths[shiftDate] = leftColWidth
                })
                setStartHour(startHours)
                setEndHour(endHours)
                setHours(hours)
                setLeftColWidth(leftColWidths)
            }
            const employmentShifts = {}
            const pageBreaks = {}
            shiftDates.forEach(shiftDate => {
                const empShifts = workShiftData?.employments
                    .map(employment => {
                        const shifts = combinedShifts[shiftDate]
                            .filter(shift => {
                                return shift?.employment_id === employment?.id
                            })
                            .map(shift => {
                                const [start_time, end_time] = parseTimeBlock(
                                    shift.time_block,
                                    timezoneOffsets[workShiftData?.store?.time_zone]
                                )
                                return {
                                    ...shift,
                                    start_time,
                                    end_time
                                }
                            })

                        const totalShiftHours = getTotalShiftHours(shifts)
                        return {
                            ...employment,
                            shifts,
                            totalShiftHours: totalShiftHours
                        }
                    })
                    .filter(employment => employment.shifts.length > 0)
                const emptyRows = []
                for (let i = 0; i < 2; i++) {
                    emptyRows.push({
                        key: `empty-${i}`,
                        user: {
                            name_first: ''
                        },
                        shifts: []
                    })
                }
                employmentShifts[shiftDate] = [...empShifts, ...emptyRows]
                pageBreaks[shiftDate] = empShifts.length >= 30
            })

            setEmploymentShifts(employmentShifts)
            setPageBreak(pageBreaks)

            const staffPerHours = {}
            shiftDates.forEach(shiftDate => {
                const staffPerHour = {}
                splittedShifts?.[shiftDate].forEach(shift => {
                    const start = moment(shift.startDate)
                    staffPerHour[start.hour()] = staffPerHour[start.hour()] ? staffPerHour[start.hour()] + 0.25 : 0.25
                })
                staffPerHours[shiftDate] = staffPerHour
            })
            setStaffPerHour(staffPerHours)
            setDoneProcessing(true)
        }
    }, [workShiftData])

    const doc = (
        <Document>
            {shiftDates.length &&
                Object.keys(workShiftData).length > 0 &&
                shiftDates.map((shiftDate, index) => {
                    const openTimeToDisplay = moment(
                        `${shiftDate} ${workShiftData?.storeHours?.[shiftDate]?.open_time}`
                    ).format('H:mm')
                    const closeTimeToDisplay = moment(
                        `${shiftDate} ${workShiftData?.storeHours?.[shiftDate]?.close_time}`
                    ).format('H:mm')
                    return (
                        <Page style={styles.body} key={index}>
                            <View style={styles.header}>
                                <Text style={styles.headerText}>ワークスケジュール表</Text>
                            </View>
                            <View style={{ ...styles.header, marginTop: 0 }}>
                                <Text style={{ fontSize: '12px' }}>
                                    {moment(shiftDate, 'YYYY-MM-DD').format('YYYY年 M月D日 ddd')}{' '}
                                    {workShiftData?.storeHours?.[shiftDate]
                                        ? `営業時間：${openTimeToDisplay}〜${closeTimeToDisplay}`
                                        : ''}
                                </Text>
                            </View>
                            <PdfSalesTable
                                predictedSales={workShiftData?.predictedSales?.[shiftDate]}
                                store={workShiftData?.store}
                                dailyNotes={workShiftData?.dailyNotes?.[shiftDate]}
                            />
                            <PdfEmploymentTable
                                employmentShifts={employmentShifts[shiftDate]}
                                shiftDate={shiftDate}
                                startHour={startHour[shiftDate]}
                                endHour={endHour[shiftDate]}
                                leftColWidth={leftColWidth[shiftDate]}
                                HOURS={hours[shiftDate]}
                            />
                            <PdfFooter
                                preds={hourlyPleds[shiftDate]}
                                predictedSales={workShiftData?.predictedSales?.[shiftDate]}
                                nextPage={pageBreak[shiftDate]}
                                hoursWithStaffReq={hoursWithStaffReq[shiftDate]}
                                hourlyEmpSalesPred={hourlyEmpSalesPred[shiftDate]}
                                leftColWidth={leftColWidth[shiftDate]}
                                HOURS={hours[shiftDate]}
                                staffPerHour={staffPerHour[shiftDate]}
                                totalActualStaff={totalActualStaff[shiftDate]}
                            />
                        </Page>
                    )
                })}
        </Document>
    )

    return (
        <>
            {buttonText ? (
                <Button
                    variant="contained"
                    style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                    aria-label="download schedule"
                    disabled={shiftDates.length === 0}
                    onClick={() => {
                        setDialogue(true)
                        getWorkShiftData()
                    }}
                >
                    {buttonText}
                </Button>
            ) : (
                <IconButton
                    aria-label="download schedule"
                    onClick={() => {
                        setDialogue(true)
                        getWorkShiftData()
                    }}
                >
                    <DownloadOutlined />
                </IconButton>
            )}
            <Dialog open={dialogue}>
                <DialogTitle>{'シフト表の出力'}</DialogTitle>
                <DialogContent>{'シフト表をPDF形式で出力します。よろしいですか？'}</DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setDialogue(false)}>
                        {'キャンセル'}
                    </Button>
                    {doneProcessing ? (
                        <PDFDownloadLink
                            document={doc}
                            fileName={`ワークスケジュール表-${workShiftData?.store?.name}-${shiftDates[0]?.slice(0, 7)}.pdf`}
                        >
                            {({ loading, error, blob }) => {
                                if (failedToFecth || error) {
                                    return 'エラーが発生しました。'
                                }
                                return loading || !blob ? (
                                    <LoadingButton
                                        loading
                                    >
                                        {'出力する'}
                                    </LoadingButton>
                                ) : (
                                    <Button
                                        onClick={() => {
                                            setDialogue(false)
                                            setSelectedDates([])
                                            setDrawerOpen(false)
                                        }}
                                        autoFocus
                                    >
                                        {'出力する'}
                                    </Button>
                                )
                            }}
                        </PDFDownloadLink>
                    ) : (
                        <LoadingButton
                            loading={!doneProcessing}
                        >
                            {'出力する'}
                        </LoadingButton>
                    )}
                </DialogActions>
            </Dialog>

            {/* Hidden PDFDownloadLink that triggers the font download. This is needed so that the font downloads 
                when the button is rendered and does not wait for the button to be clicked. */}
            <div style={{ display: 'none' }}>
                <PDFDownloadLink document={<DummyDocument />} fileName="dummy.pdf">
                    {({ loading }) => (loading ? 'Loading document...' : 'Download now!')}
                </PDFDownloadLink>
            </div>
        </>
    )
}

// Dummy document that uses the font
const DummyDocument = () => (
    <Document>
        <Page>
            <Text style={{ fontFamily: 'Yu Mincho' }}>Dummy</Text>
        </Page>
    </Document>
)

const styles = StyleSheet.create({
    body: {
        padding: 10,
        fontFamily: 'Yu Mincho'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        margin: '7.5px auto 0px'
    },
    headerText: {
        fontSize: '14px',
        fontWeight: 'bold'
    }
})

Font.register({
    family: 'Yu Mincho',
    src: yu
})

export default PdfBulkExportButton
